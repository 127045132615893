<script>

import Layout from "../../../layouts/main.vue";
import axios from "axios";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            user: [],
            sd: '',
            ed: '',
            isClicked: false
        };
    },
    methods: {
        async getUser() {
            const response = await axios.get(
                "https://api.doctosoft.com/index.php/api/user/report/" + this.sd + '/' + this.ed
            );
            this.user = response.data;
        },
        onSubmit() {
            this.isClicked = true
        }
    },
    created() {
        this.getUser()

    }
};
</script>
<template>
    <Layout>
        <div class="mb-3">
            <label for="date" class="form-label">Start Date</label>
            <input type="date" v-model="sd" class="form-control" id="exampleInputdate" />
        </div>

        <div class="mb-3">
            <label for="date" class="form-label">End Date</label>
            <input type="date" v-model="ed" class="form-control" id="exampleInputdate" />
        </div>

        <div class="action-btn">
            <button class="btn  btn-md btn btn-primary" @click="getUser(); onSubmit();">submit</button>
        </div>

        <b-row v-if="isClicked == true">
            <b-col lg="12">
                <b-card no-body id="patientTable">
                    <b-card-header class="d-flex align-items-center">
                        <h5 class="card-title flex-grow-1 mb-0">Patient's Table</h5>
                    </b-card-header>
                    <b-card-body>
                        <div>
                            <div class="table-responsive table-card mb-3">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <!-- <th scope="col" style="width: 50px;">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="checkAll"
                                                        value="option">
                                                </div>
                                            </th> -->
                                            <th class="sort" data-sort="name" scope="col">Name</th>
                                            <!-- <th class="sort" data-sort="password" scope="col">Password</th> -->
                                            <th class="sort" data-sort="email" scope="col">Email</th>
                                            <th class="sort" data-sort="phone" scope="col">Phone</th>
                                            <!-- <th class="sort" data-sort="ip" scope="col">Ip</th> -->
                                            <!-- <th scope="col"
                                                v-if="this.permission.patient.update || this.permission.patient.delete">
                                                Action
                                            </th> -->
                                        </tr>
                                    </thead>
                                    <tbody class="list form-check-all">
                                        <tr v-for="use in user" :key="use.id">
                                        <!-- <th scope="row">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" name="chk_child"
                                                    value="option1">
                                            </div>
                                        </th> -->
                                        <td class="name">{{ use.name }}</td>
                                        <!-- <td class="password">{{ patient.password }}</td> -->
                                            <td class="email">{{ use.email }}</td>
                                            <td class="phone">{{ use.phone }}</td>
                                        <!-- <td class="ip">{{ patient.ip }}</td> -->
                                        <!-- <td> -->
                                        <!-- <div class="dropdown">
                                            <b-button variant="soft-secondary" size="sm" class="dropdown" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="ri-more-fill align-middle"></i>
                                            </b-button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <b-link class="dropdown-item edit-item-btn" id="edit-item-btn"
                                                        @click="(updatePatient(patient.id))"
                                                        v-if="this.permission.patient.update">Edit</b-link>
                                                </li>
                                                <li>
                                                        <b-button class="dropdown-item remove-item-btn"
                                                            @click="confirm(patient.id)"
                                                            v-if="this.permission.patient.delete">Delete</b-button>
                                                    </li>
                                                </ul>
                                            </div> -->
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <div class="noresult" style="display: none">
                            <div class="text-center">
                              <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                                :height="75" :width="75" />
                              <h5 class="mt-2">Sorry! No Result Found</h5>
                              <p class="text-muted mb-0">We've searched more than 150+ API Keys We did not find any API for you
                                search.</p>
                            </div>
                          </div> -->
                            </div>
                            <!-- <div class="d-flex justify-content-end mt-3">
                          <div class="pagination-wrap hstack gap-2">
                            <b-link class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                              Previous
                            </b-link>
                            <ul class="pagination listjs-pagination mb-0">
                              <li :class="{ active: pageNumber == page, disabled: pageNumber == '...', }"
                                v-for="(pageNumber, index) in pages" :key="index" @click="page = pageNumber">
                                <b-link class="page" href="#">{{ pageNumber }}</b-link>
                              </li>
                            </ul>
                            <b-link class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                              Next
                            </b-link>
                          </div> -->
                            <!-- </div> -->
                        </div>
                    </b-card-body>
                </b-card>
        </b-col>
    </b-row>

</Layout></template>
